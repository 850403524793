<template>
  <div class="page">
    <el-row :gutter="10">
      <el-col :span="showList ? 6 : 1" :class="['p_r', showList ? '' : 'el-col-unfold']">
        <div :class="['unfoldClassifyBtn', showList ? 'el-icon-arrow-left':'el-icon-arrow-right']"
             @click="showList = !showList"></div>
        <div class="bg-white" v-show="showList">
          <el-input v-model="catalogueSrh" placeholder="请输入关键词" size="small"></el-input>
          <el-table
              :data="catalogueList"
              ref="catalogueTable"
              size="small"
              height="calc(100vh - 250px)"
              class="table" highlight-current-row
              style="cursor:pointer;"
              :show-header=false
              @current-change="handleCurrentChange">
            <el-table-column prop="resourceName" show-overflow-tooltip/>
          </el-table>
          <el-pagination
              @size-change="sizeChangeHandle"
              @current-change="currentChangeHandle"
              :current-page="pageNo"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="pageSize"
              :total="total"
              background
              layout="prev, pager, next">
          </el-pagination>
        </div>
      </el-col>
      <el-col :span="showList ? 18 : 24" :class="[showList ? '' : 'el-col-unfold','leftHeighe']">
        <el-form size="small" :inline="true" class="query-form" ref="searchForm" @submit.native.prevent @keyup.enter.native="submitForm(1)">
          <el-form-item label="字段名：">
            <el-input v-model.trim="fileSrh" placeholder="请输入字段名" clearable maxlength="100"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" size="small" @click="submitForm(1)">查询</el-button>
            <el-button @click="resetSearch()" size="small" icon="el-icon-refresh-right">重置</el-button>
          </el-form-item>
          <el-form-item class="f_r">
            <el-button icon="el-icon-plus" size="small" type="primary" @click="view('add', masterTableId)">新增
            </el-button>
            <el-button type="danger" size="small" icon="el-icon-delete" @click="del()">批量删除
            </el-button>
          </el-form-item>
        </el-form>
        <div class="bg-white">
          <el-table
              :data="fileList"
              size="small"
              @selection-change="selectionChangeHandle"
              v-loading="loading"
              height="calc(100vh - 296px)"
              class="table">
            <el-table-column type="selection" width="50"/>
            <el-table-column label="排序" width="110">
              <template slot-scope="scope">{{ scope.row.fieldSort }}</template>
            </el-table-column>
            <el-table-column prop="id" label="字段编号" show-overflow-tooltip/>
            <el-table-column prop="fieldName" label="字段名" show-overflow-tooltip/>
            <el-table-column prop="publishState" label="发布状态" show-overflow-tooltip>
              <template slot-scope="scope">{{ scope.row.publishState == 0 ? '已发布' : '未发布' }}</template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="200">
              <template slot-scope="scope">
                <el-button size="small" type="text" @click="view('edit', scope.row)">修改</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
              @size-change="sizeChangeHandle2"
              @current-change="currentChangeHandle2"
              :current-page="pageNo2"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="pageSize2"
              :total="total2"
              background
              layout="total, sizes, prev, pager, next, jumper">
          </el-pagination>
        </div>
      </el-col>
    </el-row>
    <AddBusinessForm ref="addRecordForm" @refreshDataList="getFileList"></AddBusinessForm>
  </div>
</template>

<script>
import AddBusinessForm from './addBusinessForm'

export default {
  components: {AddBusinessForm},
  data() {
    return {
      visibleLog: false,
      showList: true,
      // 展览
      catalogueSrh: '',
      catalogueList: [],
      pageNo: 1,
      pageSize: 10,
      total: 0,
      // 文物清单
      fileSrh: '',
      fileList: [],
      dataListSelections: [],
      loading: false,
      pageNo2: 1,
      pageSize2: 10,
      total2: 0,
      moduleId: 34,   // 当前请求拖拽排序数据id
      setShow: false, // 是否显示列表设置拖拽排序弹窗
      masterTableId: "",
    }
  },
  watch: {
    // 实时输入查询目录
    catalogueSrh() {
      this.getCatalogue();
    },
  },
  mounted() {
    this.getCatalogue();
    this.getFileList();
  },
  methods: {
    // 新增、编辑
    view(method, row) {
      if (method == 'add' && !this.masterTableId) {
        this.$message.warning('请选择资源')
        return
      }
      this.$refs.addRecordForm.init(method, row)
    },
    // 目录表格多选
    selectionChangeHandle(val) {
      this.dataListSelections = val
    },
    //查询
    submitForm(type) {
      this.pageNo2 = 0;
      this.getFileList(type)
    },
    // 查询展览
    getCatalogue() {
      this.$axios(this.api.original.businessFieldList, {
        'resourceName': this.catalogueSrh,
        'current': this.pageNo,
        'size': this.pageSize,
      }, 'get').then(data => {
        if (data && data.status) {
          this.catalogueList = data.data.records
          this.total = parseInt(data.data.total)
        }
      })
    },
    // 重置
    resetSearch() {
      this.fileSrh = '';
      this.getFileList();
      this.masterTableId = ""
    },
    // 点击展览行
    handleCurrentChange(val) {
      this.$refs.catalogueTable.setCurrentRow(val);
      this.masterTableId = val.id
      this.getFileList()
    },
    // 查询目录
    getFileList(type) {
      if (type == 1) {
        this.pageNo2 = 1
      }
      this.loading = true
      this.$axios(this.api.original.businessFieldDesciptionList, {
        'masterTableId': this.masterTableId,
        "fieldName": this.fileSrh,
        'current': this.pageNo2,
        'size': this.pageSize2
      }, 'get').then(data => {
        if (data && data.status) {
          console.log(data.data);
          this.fileList = data.data.records
          this.total2 = parseInt(data.data.total)
          this.loading = false
        }
      })
    },
    // 删除、批量删除
    del(id) {
      if (!id && !this.dataListSelections.length) {
        this.$message.warning('请至少选择一条数据')
        return
      }
      // 批量删除多个id用逗号隔开
      let ids = id || this.dataListSelections.map(item => {
        return item.id
      })
      this.$confirm(`确定删除所选项吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = true
        this.$axios(this.api.original.businessFieldDesciptionRemoveByIds, ids, 'post').then(data => {
          this.$message.success("删除成功")
          this.getFileList()
        })
      })
    },
    // 展览每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageNo = 1;
      this.getCatalogue();
    },
    // 展览当前页
    currentChangeHandle(val) {
      this.pageNo = val;
      this.getCatalogue();
    },
    // 每页数
    sizeChangeHandle2(val) {
      this.pageSize2 = val;
      this.pageNo2 = 1;
      this.getFileList();
    },
    // 当前页
    currentChangeHandle2(val) {
      this.pageNo2 = val;
      this.getFileList();
    },
  }
}
</script>

<style scoped>

</style>
